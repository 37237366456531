import React, { useState, useEffect, useRef, Fragment } from "react";
import SearchItem from "./searchitem/Searchitem";
import "./Searchbox.css";
import { ReactComponent as SearchInputIcon } from "../../assets/icons/search-input-icon.svg";
import { connect } from "react-redux";
import { Form, Col } from "react-bootstrap";
import Refresh_icon from "../../assets/icons/refresh-icon.svg";
import Button from "../../components/shared/UI/Button/Button";
import * as actions from "../../store/actions/index";
import Backdrop from "../../components/shared/UI/Backdrop/Backdrop.js";
import CommanModal from "../../components/shared/UI/commanModal/CommanModal.js";
import displayLogo from "../../assets/icons/LSM_display.svg";
import CloseLogoDark from "../../assets/icons/Close-darkbg.svg";
import Select from "react-select";
import invertedArrow from "../dropdownArrow/dropdownArrow";
import { ReactComponent as SearchFilter } from "../../assets/icons/SearchFilter.svg";
import { useScroll } from "../../hooks/useScroll.js";
import { Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Searchbox = (props) => {
  const {
    onSearchCurrentLiveShot,
    token,
    showLoader,
    onShowLoader,
    type,
    newLiveshot,
    closeModal,
    blockId,
    errorMsg,
    showSearchErrorModal,
    onHideModal,
    controlRoomList,
    currentCanvas,
    facilityList,
    closeErrorBar,
    openErrorBar,
    isError,
    userFacilityId,
    clearSearchTerm,
  } = props;
  const { searchList, currentUserRole } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLocationTerm, setSearchLocationTerm] = useState("");
  const [searchVenueNameTerm, setSearchVenueNameTerm] = useState("");
  const [disableSearchByName, setDisableSearchByName] = useState(false);
  const [disableSearchByLocation, setDisableSearchByLocation] = useState(false);
  const [searchType, setSearchType] = useState(0);
  const inputElement = useRef(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchHitInterval, setSearchHitInterval] = useState("");
  const searchRef = useRef(null);
  let [filteredSearchData, setFilteredSearchData] = useState([]);
  const [facilityListData, setFacilityListData] = useState([]);
  const defaultList = { value: 0, key: 0, label: "ALL FACILITIES" };
  const [facilityId, setFacilityId] = useState(0);
  const [selected, setSelected] = useState(defaultList);
  const hasScroll = useScroll(searchRef, [filteredSearchData]);
  const [tooltip, setTooltip] = useState(false);
  useEffect(() => {
    if (controlRoomList && currentCanvas) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      setCurrentRoom(room);
    }
  }, [controlRoomList, currentCanvas]);
  useEffect(() => {
    const list = facilityList.map((option) => ({
      value: option.facilityId,
      key: option.facilityId,
      label: option.facilityName,
    }));
    setFacilityListData([defaultList, ...list]);
  }, [facilityList]);
  useEffect(() => {
    if (!newLiveshot) {
      if (inputElement.current) {
        inputElement.current.focus();
        setSearchTerm("");
        setSearchHitInterval("");
        setSearchLocationTerm("");
        setSearchVenueNameTerm("");
        setDisableSearchByLocation(false);
        setDisableSearchByName(false);
        setFacilityId(userFacilityId);
        onShowLoader();
        onSearchCurrentLiveShot(token);
      }
    }
  }, [
    newLiveshot,
    onShowLoader,
    onSearchCurrentLiveShot,
    token,
    userFacilityId,
  ]);

  useEffect(() => {
    setSearchType(type);
    let newData = {};
    newData.abstractLiveshotId = "1abc";
    newData.flag = true;
    newData.abstractLiveshotName = "";
    newData.hitInterval = "";
    newData.venueType = "E.G. 30R LOCATION, SKYPE, QUICKLINK...";
    newData.location = "";
    newData.studioName = "";
    // newData.liveshotTypeId = Number(type);
    newData.facilityId = Number(facilityId);
    let searchListDuplicate = searchList.filter(
      (liveshot) => liveshot.abstractLiveshotId === "1abc"
    );
    if (searchListDuplicate.length === 0) {
      // searchList.push(newData);
    } else {
      searchList.forEach((search) => {
        if (search.abstractLiveshotId === "1abc") {
          search.facilityId = Number(facilityId);
        }
      });
    }
    let filteredData = searchList.filter((liveShotType) => {
      if (liveShotType.abstractLiveshotName === null) return null;
    });

    if (facilityId !== 0) {
      filteredData = searchList.filter((liveShotType) => {
        if (liveShotType.abstractLiveshotName === null) return null;
        return liveShotType.facilityId === Number(facilityId);
      });
      let newLiveshot = filteredData.filter((liveshot) => {
        return liveshot.flag === true;
      });
      if (newLiveshot.length > 0) {
        newLiveshot[0].abstractLiveshotName = searchTerm;
        newLiveshot[0].hitInterval = searchHitInterval;
      }
    } else {
      filteredData = searchList;
    }
    sortLiveShotData(filteredData);
    setFilteredSearchData(filteredData);
  }, [type, searchList, facilityId]);

  const dropdownIndicator = (props) => {
    return <SearchFilter {...props} />;
  };

  useEffect(() => {
    let filterSearchData = [];
    let filterByfacilityId = facilityId !== 0;
    let filterBySearchType = false; // searchType !== 0;

    if (searchTerm) {
      filterSearchData = searchList.filter((liveShotType) => {
        return (
          liveShotType.abstractLiveshotName !== null &&
          (!filterByfacilityId ||
            liveShotType.facilityId === Number(facilityId)) &&
          (!filterBySearchType ||
            liveShotType.liveshotTypeId === Number(searchType)) &&
          (liveShotType.abstractLiveshotName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            (liveShotType.venue !== null &&
              liveShotType.venue
                .toLowerCase()
                .includes(searchTerm.toLowerCase())))
        );
      });
    } else {
      filterSearchData = searchList.filter((liveShotType) => {
        return liveShotType.abstractLiveshotName !== null;
      });
    }
    sortLiveShotData(filterSearchData);
    setFilteredSearchData(filterSearchData);
  }, [searchTerm, searchLocationTerm, searchVenueNameTerm]);

  useEffect(() => {
    if (clearSearchTerm) setSearchTerm("");
  }, [clearSearchTerm]);

  const sortLiveShotData = (liveShotData) => {
    liveShotData.sort((a, b) =>
      a.abstractLiveshotName > b.abstractLiveshotName
        ? 1
        : b.abstractLiveshotName > a.abstractLiveshotName
        ? -1
        : 0
    );
  };

  const onRefreshSearch = () => {
    setFilteredSearchData([]);
    onShowLoader();
    onSearchCurrentLiveShot(token);
    setSearchTerm("");
    setSearchLocationTerm("");
    setSearchVenueNameTerm("");
    setDisableSearchByName(false);
    setDisableSearchByLocation(false);
  };

  const handleOnChange = (chosenValue) => {
    setTooltip(false);
    setFacilityId(Number(chosenValue.value));
    setSelected(chosenValue);
  };

  const onUpdateSearch = (e) => {
    setSearchTerm(e.target.value);
    setSearchLocationTerm(e.target.value);
    setSearchVenueNameTerm(e.target.value);
    // if (e.target.value) {
    //   setDisableSearchByName(true);
    // } else {
    //   setDisableSearchByName(false);
    // }
  };
  // const onUpdateSearchLocation = (e) => {
  //   setSearchLocationTerm(e.target.value);
  //   if (e.target.value) {
  //     setDisableSearchByName(true);
  //   } else {
  //     setDisableSearchByName(false);
  //   }
  // };
  let commanModal = null;
  if (showSearchErrorModal) {
    commanModal = (
      <Fragment>
        <Backdrop show={showSearchErrorModal} clicked={onHideModal} />
        <CommanModal closeModal={onHideModal} message={errorMsg} />
      </Fragment>
    );
  }
  return (
    <Fragment>
      {commanModal}
      <div
        className={
          selected.label === "vprod-1"
            ? "Searchbox_container vprod-paddingright"
            : "Searchbox_container"
        }
      >
        <div className="search-header-row">
          <img
            src={displayLogo}
            alt="display logo"
            className="search-display-logo"
          />
          <label className="search-header-title">Search Live Shots</label>

          {/* <img 
        src={CloseLogoDark}
        className="search-closeicon" 
        alt="close icon"
        /> */}
          <svg
            onClick={props.modalClosed}
            // modalClosed={() => setCreateNewLS(false)}
            className="search-closeicon"
            width="22"
            height="22"
            fill=""
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8301 7.16983C15.2532 7.5929 15.2532 8.27883 14.8301 8.70189L12.532 11L14.8301 13.2981C15.2532 13.7212 15.2532 14.4071 14.8301 14.8302C14.4071 15.2532 13.7211 15.2532 13.2981 14.8302L11 12.5321L8.70186 14.8302C8.27879 15.2532 7.59287 15.2532 7.1698 14.8302C6.74673 14.4071 6.74673 13.7212 7.1698 13.2981L9.4679 11L7.1698 8.70189C6.74673 8.27882 6.74673 7.5929 7.1698 7.16983C7.59287 6.74676 8.27879 6.74676 8.70186 7.16983L11 9.46792L13.2981 7.16983C13.7211 6.74676 14.4071 6.74676 14.8301 7.16983Z"
              fill=""
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.8333 11C21.8333 16.9831 16.983 21.8333 11 21.8333C5.01687 21.8333 0.166626 16.9831 0.166626 11C0.166626 5.01691 5.01687 0.166656 11 0.166656C16.983 0.166656 21.8333 5.01691 21.8333 11ZM19.6666 11C19.6666 15.7865 15.7864 19.6667 11 19.6667C6.21349 19.6667 2.33329 15.7865 2.33329 11C2.33329 6.21352 6.21349 2.33332 11 2.33332C15.7864 2.33332 19.6666 6.21352 19.6666 11Z"
              fill=""
            />
          </svg>
        </div>

        <div className="search-box-row">
          <Input
            placeholder="SEARCH"
            className={`common-search-input ${searchTerm ? "filled" : ""}`}
            prefix={<SearchInputIcon className="search-input-icon" />}
            value={searchTerm || ""}
            onChange={onUpdateSearch}
            ref={inputElement}
            disabled={disableSearchByName}
          />

          {
            <Tooltip
              trigger="hover"
              placement="top"
              title={!isOpen && tooltip ? "FILTER BY FACILITY" : undefined}
              className="facility-tooltip"
            >
              <span
                onMouseLeave={() => {
                  setTooltip(false);
                }}
                onMouseEnter={() => {
                  setTooltip(true);
                }}
              >
                <Select
                  onChange={handleOnChange}
                  classNamePrefix="add-ls-selection"
                  className={
                    isOpen === true
                      ? "add-ls-select green-bg black-text"
                      : selected
                      ? "add-ls-select white-text grey-bg"
                      : !selected && isOpen
                      ? "add-ls-select green-bg black-text"
                      : "add-ls-select grey-text grey-bg"
                  }
                  onMenuOpen={() => setIsOpen(true)}
                  onMenuClose={() => setIsOpen(false)}
                  components={{ DropdownIndicator: dropdownIndicator }}
                  placeholder="SELECT FACILITY"
                  value={selected}
                  cache={{ "": [{ label: "", value: null, disabled: true }] }}
                  options={facilityListData}
                />
              </span>
            </Tooltip>
          }
          <div className="search-refresh-btn">
            <Button onClick={onRefreshSearch} className="search-refresh-btn">
              {/* <img 
                  className="search-refresh-icon" 
                  src={Refresh_icon} 
                  alt="refresh icon"
                  /> */}
              <svg
                className="search-refresh-icon"
                alt="refresh icon"
                width="18"
                height="18"
                fill=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2791 13.7892L13.3984 12.9084C12.8674 12.3775 11.9608 12.6204 11.7665 13.3457L10.5802 17.773C10.3859 18.4983 11.0495 19.1619 11.7748 18.9676L16.2021 17.7813C16.9274 17.587 17.1703 16.6804 16.6394 16.1495L15.8454 15.3555C18.5385 11.9473 18.3117 6.98648 15.1651 3.83982C13.7248 2.39953 11.9044 1.57097 10.0268 1.35415L9.44523 3.50468C10.9631 3.6026 12.4532 4.23159 13.6132 5.39165C15.9014 7.67986 16.1234 11.2519 14.2791 13.7892Z"
                  fill=""
                />
                <path
                  d="M7.4204 2.22692C7.61473 1.50165 6.95107 0.837982 6.2258 1.03232L1.79845 2.21862C1.07317 2.41296 0.830255 3.31955 1.36119 3.85048L4.60223 7.09153C5.13317 7.62246 6.03976 7.37954 6.23409 6.65427L7.4204 2.22692Z"
                  fill=""
                />
                <path
                  d="M2.15472 4.64408C-0.538471 8.05225 -0.311712 13.0132 2.83499 16.1599C4.27626 17.6011 6.09814 18.4299 7.9771 18.646L8.55871 16.4953C7.03948 16.3982 5.54788 15.7691 4.38682 14.608C2.09857 12.3198 1.87665 8.74769 3.72104 6.21041L2.15472 4.64408Z"
                  fill=""
                />
              </svg>
              Refresh
            </Button>
          </div>
        </div>
        <div
          ref={searchRef}
          className={hasScroll ? "searchbox padding-space" : "searchbox"}
        >
          {filteredSearchData.map((searchObj) => (
            <SearchItem
              key={searchObj.abstractLiveshotId}
              searchInfo={searchObj}
              currentUserRole={currentUserRole}
              clicked={props.modalClosed}
              closeErrorBar={closeErrorBar}
              openErrorBar={openErrorBar}
              isError={isError}
              blockId={blockId}
              room={currentRoom}
              facilityId={facilityId}
            />
          ))}
          {searchTerm ? (
            <SearchItem
              key={0}
              searchInfo={[]}
              searchTermValue={searchTerm}
              currentUserRole={currentUserRole}
              clicked={props.modalClosed}
              blockId={blockId}
              room={currentRoom}
              facilityId={facilityId}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    searchList: state.searchListInfo.searchList,
    token: state.auth.accessToken,
    showLoader: state.searchListInfo.showLoader,
    newLiveshot: state.liveShotContainer.newLiveshot,
    errorMsg: state.searchListInfo.errorMsg,
    showSearchErrorModal: state.searchListInfo.showSearchErrorModal,
    currentCanvas: state.canvasInfo.currentCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    facilityList: state.showInfo.facilityList,
    userFacilityId: state.user.userFacilityId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchCurrentLiveShot: (token) => dispatch(actions.searchLiveShot(token)),
    onShowLoader: () => dispatch(actions.showLoader()),
    onHideModal: () => dispatch(actions.hideSearchErrorModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Searchbox);

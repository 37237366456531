import axios from "../../axios";
import * as actionTypes from "./actionTypes";

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: data.access_token,
    currentUser: {
      sso: data.sso,
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
    },
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authEnd = () => {
  return {
    type: actionTypes.AUTH_END,
  };
};
const overrideAuth = process.env.REACT_APP_AUTH_OVERRIDE_TOKEN;
let refreshTokenInterval;

const setRefreshTokenInterval = (dispatch) => {
  refreshTokenInterval && clearInterval(refreshTokenInterval);
  let intervalTime = process.env.REACT_APP_AUTH_INTERVAL
    ? process.env.REACT_APP_AUTH_INTERVAL
    : 5400000;
  if (!overrideAuth) {
    refreshTokenInterval = setInterval(() => {
      dispatch(getRefreshToken());
    }, intervalTime);
  }
};

export const authCheck = () => {
  return (dispatch) => {
    dispatch(authStart());
    let userInfo = JSON.parse(localStorage.getItem("currentUser"));
    let uniqueKeyId = overrideAuth
      ? process.env.REACT_APP_AUTH_OVERRIDE_TOKEN
      : localStorage.getItem("id");
    axios
      .get(`/auth/token?id=${uniqueKeyId}`, { withCredentials: true })
      .then((response) => {
        localStorage.setItem("currentUser", JSON.stringify(response.data));
        localStorage.setItem("refresh_token", response.data.refresh_token);
        dispatch(authSuccess(response.data));
        setRefreshTokenInterval(dispatch);
      })
      .catch((err) => {
        dispatch(authEnd());
        console.log("error ", err);
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 500)
        ) {
          if (userInfo) {
            dispatch(getRefreshToken());
          } else {
            dispatch(authRemove());
          }
        }
      });
  };
};

export const getRefreshToken = () => {
  return (dispatch) => {
    let accessToken = localStorage.getItem("id");
    if (accessToken && accessToken != "") {
      let refreshToken = localStorage.getItem("refresh_token");
      dispatch(authStart());
      axios
        .get(
          `/auth/refresh-token?refresh-token=${refreshToken}&id=${accessToken}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log("refresh-token response", response);
          localStorage.setItem("currentUser", JSON.stringify(response.data));
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("id", response.data.access_token);
          dispatch(authSuccess(response.data));
          setRefreshTokenInterval(dispatch);
        })
        .catch((err) => {
          dispatch(authEnd());
          console.log("error ", err);
          if (
            err.response.status &&
            (err.response.status === 401 || err.response.status === 500)
          ) {
            dispatch(authRemove());
          }
        });
    } else {
      refreshTokenInterval && clearInterval(refreshTokenInterval);
    }
  };
};

export const authRemove = () => {
  return {
    type: actionTypes.AUTH_REMOVE,
  };
};
